<template>
  <div class="motivo content-cancelamento">
    <vs-row>
      <vs-col vs-lg="4" vs-sm="12" v-if="$route.name == 'endosso-cancelamento'">
        <!-- validar depois a mensagem para colocar uma diferente quando for fim de vigência  <p>{{endosso.endorsementTypeId}}</p> -->
        <h6 class="text-grey-700 font-semibold" style="" v-if="selectedReason">
          A Apólice só poderá ser cancelada até o
          {{ daysToCancel }}º dia da emissão da Apólice. Após esta data, não
          poderá ser efetuado de forma automática.
          <br />
          <br />
          O cancelamento automático só poderá ser realizado se não houver prêmio
          quitado.
        </h6>
      </vs-col>

      <vs-col vs-lg="4" vs-sm="12" v-else>
        <h6 class="text-grey-700 font-semibold" style="">
          Selecione o motivo da alteração da apólice.
        </h6>
      </vs-col>

      <vs-col vs-lg="8" vs-sm="12">
        <vs-row>
          <vs-col vs-w="12" class="mb-5">
            <ValidationProvider
              name="Motivo"
              rules="required"
              v-slot="{ errors }"
            >
              <el-select
                v-model="razao"
                clearable
                placeholder="Selecione um motivo"
                @input="motivoSelected()"
              >
                <el-option
                  v-for="item in listMotivos"
                  :key="item.ReasonEndorsementRequestDescription"
                  :label="item.ReasonEndorsementRequestDescription"
                  :value="item.ReasonEndorsementRequestDescription"
                >
                </el-option>
              </el-select>
              <span class="text-danger text-sm" v-show="errors[0]">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
          </vs-col>
          <!-- </div> -->

          <!-- Objeto -->
          <div v-if="endossoObjeto.length > 0">
            <contractual-terms
              v-if="
                listObject && listObject.length && endosso.EndorsementReasonId
              "
              :defaultTags="defaultTags"
              :isEndosso="true"
              :endossoObjeto="endossoObjeto"
              @updatePropostaTermos="updatePropostaTermos"
              currentTab="2"
            ></contractual-terms>
          </div>
          <!-- <div v-else  style="width: 100%">
          <div
            class="vs-row"
            style="display: flex; width: 100%"
          >
            <div data-v-6fa74270="" class="w-full mt-8">
              <p data-v-6fa74270="" class="font-semibold">Objeto</p>
              <p
                class="text-objeto p-5"
                style="
                  width: 100%;
                  height: 140px;
                  border-radius: 8px !important;
                "
              >
                Objeto do endosso em branco!
              </p>
            </div>
          </div>
          </div> -->
          <div
            class=" docs"
            v-if="((endosso || {}).Documents || []).length > 0"
          >
            <div class="head">
              <h3>Documentos</h3>
              <!-- <el-button
                type="primary"
                icon="el-icon-plus"
                style="margin-top:9px"
                @click="addCustomDocument()"
                circle
                >Adicionar documento</el-button
              > -->
            </div>
            <p
              v-if="((endosso || {}).Documents || []).length > 0"
              class="pt-5 w-full"
            >
              Documentos marcados com <b style="color: red">*</b> são
              obrigatórios
            </p>
            <div
              class="document-requests"
            >
              <div
                class="mt-2"
                :key="index"
                v-for="(doc, index) in endosso.Documents"
              >
                <p class="font-bold text-sm title">
                  {{ doc.Name }}
                  <b class="isRequired">{{ doc.IsRequired ? "*" : "" }}</b>
                </p>

                <div class="endosso-documents">
                  <p class="description">{{ doc.Description }}</p>

                  <div class="inline-flex pt-5">
                    <vs-chip :class="getClassStatus(doc)">
                      <i
                        :class="[
                          { 'onpoint-check-circle pr-2': doc.files },
                          { 'onpoint-warning pr-2': !doc.files }
                        ]"
                      ></i>
                      {{
                        doc.files
                          ? "Enviado"
                          : doc.IsRequired
                          ? "Pendente"
                          : "Opcional"
                      }}
                    </vs-chip>

                    <vs-button
                      class="py-2 px-3 ml-5"
                      color="primary"
                      type="filled"
                      v-if="!doc.files"
                      @click="openModalUpload(doc)"
                    >
                      <i class="onpoint-cloud-arrow-up icon-font-center"></i>
                    </vs-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </vs-row>
      </vs-col>
    </vs-row>
    <base64Upload
      v-if="modalUpload"
      :modalUpload="modalUpload"
      :documentoName="(selectedDocumento || {}).Name"
      @callBackUpload="callBackUpload"
      @closeModal="closeModal"
    />
    <vs-popup
      class="fix-scroll"
      title="Adicionar documento personalizado"
      :active.sync="addCustomDocumentModal"
      id="confirmacao-modal"
    >
      <div class="flex flex-col items-center pt-10">
        <vs-input
          label="Nome do documento"
          size="large"
          class="w-full"
          v-model="customName"
        />
      </div>

      <div class="text-right px-6 pb-6">
        <vs-button
          color="grey"
          class="mt-8 mr-3"
          @click="addCustomDocumentModal = false"
          >Cancelar</vs-button
        >
        <vs-button
          color="primary"
          class="mt-8 mr-3"
          type="filled"
          :disabled="customName.length <= 0"
          @click="addDocumentAction()"
          >Prosseguir</vs-button
        >
      </div>
    </vs-popup>
  </div>
</template>

<script>
import Upload from "@/components/az-upload/AzUpload.vue";
import base64Upload from "@/components/base64-Upload/multi_upload_endosso.vue";
import ContractualTerms from "@/components/contractual-terms/ContractualTerms.vue";

import { mapGetters, mapActions, mapMutations } from "vuex";
import TagHelpers from "@/helpers/TagHelpers";

export default {
  name: "dados-motivo-endosso",
  components: { Upload, ContractualTerms, base64Upload },
  data() {
    return {
      modalUpload: false,
      error: "",
      selectedDocumento: {},
      uploadDocumentoController: {},
      defaultTags: [],
      endossoObjeto: [],
      daysToCancel: 0,
      razao: "",
      addCustomDocumentModal: false,
      customName: ""
    };
  },
  props: {
    listMotivos: Array,
    listObject: Array,
    listObjectArray: Array,
    endorsementChangeTypeIds: Array
  },
  computed: {
    ...mapGetters("apolice-module", ["apolice"]),
    ...mapGetters("endosso-module", ["endosso"]),

    termos() {
      return (this.endosso.ContractualTerms || {}).Endorsement || [];
    },

    selectedReason() {
      if (this.endosso.EndorsementReasonId)
        return (
          this.listMotivos.find(
            x => x.Id == this.endosso.EndorsementReasonId
          ) || {}
        );

      return {};
    }
  },
  methods: {
    ...mapMutations("endosso-module", ["updateEndosso"]),
    ...mapActions("endosso-module", [
      "loadEndorsementDocuments",
      "getNumberOfDaysToCancelPolicy"
    ]),
    closeModal() {
      this.modalUpload = false;
    },
    addCustomDocument() {
      this.addCustomDocumentModal = true;
      this.customName = "";
    },
    addDocumentAction() {
      let doc = {
        Name: this.customName,
        EndorsementRequestDocumentId: 22,
        Description: "Documento customizado",
        IsRequired: false
      };
      const documents = Object.assign([], this.endosso.Documents);
      documents.push(doc);
      this.updateEndosso({
        Documents: documents
      });
      this.addCustomDocumentModal = false;
      this.$notify({
        showClose: true,
        message: "Documento adicionado!",
        type: "success"
      });
    },
    callBackUpload(callback) {
      console.log("callback", callback)
      let obj = {
        DocumentName: this.selectedDocumento.Name,
        EndorsementRequestDocumentId: this.selectedDocumento.Id
          ? this.selectedDocumento.Id
          : 22,
        files: callback
      };
      this.modalUpload = false;
      const documents = Object.assign([], this.endosso.Documents);
      documents.forEach(doc => {
        if (doc.Id == this.selectedDocumento.Id) {
          doc.files = obj;
        }
      });
      console.log("callback", documents)
      this.updateEndosso({
        Documents: documents
      });
    },

    async motivoSelected() {
      let select = {};
      this.listMotivos.map(term => {
        if (term.ReasonEndorsementRequestDescription == this.razao) {
          select = term;
        }
      });
      // return;
      this.$emit("dispatchList", select.ReasonEndorsementRequestId);
      setTimeout(async() => {
        if (select.ReasonEndorsementRequestId) {
          this.defaultTags = TagHelpers.defaultTagsEndosso(
            this.endosso,
            this.apolice,
            select
          );
          this.setContractualTerm(select.ReasonEndorsementRequestId);
          if (
            select.ReasonEndorsementRequestId !=
            this.endosso.EndorsementReasonId
          ) {
            this.updateEndosso({
              Documents: [],
              EndorsementReasonId: null
            });

            await this.$onpoint.loading(async() => {
              return await this.loadEndorsementDocuments({
                endorsementId: this.endosso.EndorsementTypeId,
                EndorsementReasonId: select.ReasonEndorsementRequestId,
                modalityId: this.apolice.Modality.Id
              })
                .then(response => {
                  this.updateEndosso({
                    Documents: response,
                    EndorsementReasonId: select.ReasonEndorsementRequestId
                  });
                })
                .catch(error => {
                  this.$onpoint.errorModal(error.response.Message);
                });
            });
          }
        } else {
          this.updateEndosso({
            Documents: [],
            EndorsementReasonId: null
          });
        }
      }, 0);
    },

    async loadGetNumberOfDaysToCancelPolicy() {
      if (this.$route.name != "endosso-alteracao")
        await this.$onpoint.loading(async() => {
          return await this.getNumberOfDaysToCancelPolicy().then(
            result => (this.daysToCancel = result.NumberOfDaysToCancelPolicy)
          );
        });
    },

    setContractualTerm(motivo) {
      // if (this.endosso.EndorsementTypeId === 1) {
      //   this.listObject = this.listObject.filter(
      //     (term) => term.ReasonRef === motivo
      //   );
      // }

      this.endossoObjeto = this.listObject.map(term => {
        let tagValue = {};
        let value = "";
        if (term.Value.includes("O Objeto da apólice fica alterado para:")) {
          value = "- O Objeto da apólice fica alterado para: [TAG_OBJETO]";
        } else {
          value = term.Value;
        }
        let tags = eval(term.JSONTag) || [];
        if (value) {
          let endossoTerm =
            ((this.endosso.ContractualTerms || {}).Endorsement || []).find(
              x => x.ContractualTermId == term.Id
            ) || {};
          if (
            endossoTerm &&
            endossoTerm.JSONTagValue &&
            endossoTerm.JSONTagValue != "{}"
          ) {
            tagValue = JSON.parse(endossoTerm.JSONTagValue) || {};
          }

          tags.forEach(tag => {
            value = value.split(tagValue[tag.Name]).join(`[${tag.Name}]`);
          });

          return {
            JSONTag: JSON.stringify(tags.filter(tag => tag.Name != tag.Label)),
            JSONTagValue: tagValue ? JSON.stringify(tagValue) : null,
            Value: value,
            ContractualTermId: term.Id
          };
        }
      });
    },

    updatePropostaTermos(value) {     
      
      let obj = "";
      value.termos.forEach(term => {
        if (term.Value) {
          obj += term.Value;
        }
      });

      this.updateEndosso({
        ContractualTerms: {
          ...this.endosso.ContractualTerms,
          Endorsement: value.termos            
        },
        EndorsementObject: obj
      });
    },

    openModalUpload(doc) {
      this.selectedDocumento = doc;
      this.modalUpload = true;
      this.error = false;
    },

    getClassStatus(doc) {
      if (doc.files && doc.files.EndorsementRequestDocumentId) return "success";
      return "warning";
    },

    hasDocuments(files) {
      return files && files.length;
    }
  },
  mounted() {
    this.loadGetNumberOfDaysToCancelPolicy();

    if (this.endosso.EndorsementReasonId)
      this.motivoSelected(this.endosso.EndorsementReasonId);
  }
};
</script>

<style lang="scss">
.destaque-texto {
  font-weight: 600 !important;
  color: rgba(var(--vs-danger), 1) !important;
}

.text-objeto {
  border: solid 1px rgba(0, 0, 0, 0.2);
  max-height: 300px;
  overflow-y: auto;
  background: white !important;
}

.vs__dropdown-toggle {
  background: white !important;
}
.motivo {
  .vx-row {
    margin: 0 auto;

    @media (min-width: 1199px) {
      width: 80% !important;
    }

    @media (max-width: 749px) {
      width: 100% !important;
    }
  }
}

.popup-upload-documento {
  z-index: 52999 !important;
}

</style>

<style lang="scss" scoped>
.docs .head {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  h3 {
    color: #8f8f8f;
    margin-top: 20px;
  }
}
.con-vs-chip {
  background: #ffffff !important;
  border: 2px solid;
  border-radius: 11px;
  font-weight: bolder !important;

  &.success {
    border-color: rgba(var(--vs-success), 1) !important;
    color: rgba(var(--vs-success), 1) !important;
  }

  &.warning {
    border-color: rgba(var(--vs-warning), 1) !important;
    color: rgba(var(--vs-warning), 1) !important;
  }

  &.danger {
    border-color: rgba(var(--vs-danger), 1) !important;
    color: rgba(var(--vs-danger), 1) !important
    ;
  }
}

.icon-font-center::before{
  font-size: 16px !important;
  position: relative;
  top: 2px;
}

.document-requests{
  display: flex;
  width: 100%;  
  .title{   
    width: 200px;
    min-height: 40px;
  }
  .description {
    min-height: 50px;
    width: 180px;
    padding: 2px;
  } 
  flex-wrap: wrap;
  .endosso-documents {
    border-left: 4px solid #d1d1d1;
    padding-left: 15px;
    margin: 10px 0;
    width: 200px;
    button {
      border-radius: 11px !important;
    }
  }
}
.isRequired{
  color: red
}
</style>
