var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value:
            (_vm.apolice && _vm.apolice.PolicyUniqueId) ||
            _vm.$route.name == "endosso-emissao",
          expression:
            "\n    (apolice && apolice.PolicyUniqueId) || $route.name == 'endosso-emissao'\n  ",
        },
      ],
    },
    [
      _c("breadcrumb", {
        staticClass: "mb-6 w-full",
        attrs: {
          title: `Endosso de ${_vm.tipo}`,
          actualPage: _vm.apolice.PolicyNumber || (_vm.proposta || {}).Number,
        },
      }),
      _c(
        "vs-card",
        { staticClass: "p-8" },
        [
          _c("ValidationObserver", {
            attrs: { id: "endosso-wizard" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ invalid }) {
                  return [
                    (
                      _vm.startWizard == 2
                        ? (_vm.proposta || {}).UniqueId ==
                          _vm.$route.params.uniqueId
                        : true
                    )
                      ? _c(
                          "form-wizard",
                          {
                            staticClass: "proposta",
                            attrs: {
                              color: "rgba(var(--vs-primary), 1)",
                              title: null,
                              subtitle: null,
                              startIndex: _vm.startWizard,
                              finishButtonText: "Submit",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "step",
                                  fn: function (props) {
                                    return [
                                      _c("wizard-step", {
                                        key: props.tab.title,
                                        attrs: {
                                          tab: props.tab,
                                          transition: props.transition,
                                          disabled: {}.StatusId === 3,
                                          index: props.index,
                                          showNumber: true,
                                        },
                                      }),
                                    ]
                                  },
                                },
                                {
                                  key: "footer",
                                  fn: function (props) {
                                    return [
                                      _c("div", [
                                        _c(
                                          "div",
                                          { staticClass: "wizard-footer-left" },
                                          [
                                            props.activeTabIndex > 0 &&
                                            props.activeTabIndex <= 1
                                              ? _c(
                                                  "vs-button",
                                                  {
                                                    attrs: {
                                                      color: "primary",
                                                      type: "border",
                                                    },
                                                    nativeOn: {
                                                      click: function ($event) {
                                                        props.prevTab()
                                                        _vm.currentPage--
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "font-semibold pr-2 pl-2",
                                                      },
                                                      [_vm._v("Voltar")]
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "wizard-footer-right",
                                          },
                                          [
                                            props.activeTabIndex <= 1
                                              ? [
                                                  _c(
                                                    "vs-button",
                                                    {
                                                      staticClass: "mr-5",
                                                      attrs: { color: "grey" },
                                                      nativeOn: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.resetarEndosso()
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "font-semibold pr-2 pl-2",
                                                        },
                                                        [_vm._v(" Cancelar ")]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "vs-button",
                                                    {
                                                      attrs: {
                                                        disabled:
                                                          _vm.disableProsseguirButton(
                                                            props.activeTabIndex
                                                          ) || invalid,
                                                        color: "primary",
                                                      },
                                                      nativeOn: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          props.activeTabIndex ==
                                                          0
                                                            ? _vm.loadPostValidateEndorsement(
                                                                props
                                                              )
                                                            : _vm.enviarEndossoAnalise()
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "font-semibold pr-2 pl-2",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                    " +
                                                              _vm._s(
                                                                props.activeTabIndex ==
                                                                  0
                                                                  ? "Prosseguir"
                                                                  : "Enviar para a seguradora"
                                                              ) +
                                                              "\n                  "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              : _vm._e(),
                                          ],
                                          2
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [
                            _c(
                              "tab-content",
                              {
                                staticClass: "mb-8 mt-8",
                                attrs: {
                                  title: "Informações da apólice/endosso",
                                },
                              },
                              [
                                _vm.$route.name != "endosso-emissao"
                                  ? _c("router-view")
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "tab-content",
                              {
                                staticClass: "mb-8 mt-8",
                                attrs: { title: "Motivos e documentos" },
                              },
                              [
                                _vm.listMotivos &&
                                _vm.listMotivos.length &&
                                _vm.apolice &&
                                _vm.currentPage == 1 &&
                                _vm.$route.name != "endosso-emissao"
                                  ? _c("dados-motivo-endosso", {
                                      attrs: {
                                        listMotivos: _vm.listMotivos,
                                        listObject: _vm.listObject,
                                        listObjectArray: _vm.listObjectArray,
                                        endorsementChangeTypeIds:
                                          _vm.endorsementChangeTypeIds,
                                        apolice: _vm.apolice,
                                      },
                                      on: {
                                        dispatchList: _vm.listObjectDispatch,
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _c(
                              "tab-content",
                              {
                                staticClass: "mb-8 mt-8",
                                attrs: { title: "Dados financeiros" },
                              },
                              [
                                _vm.$route.name == "endosso-emissao"
                                  ? _c("router-view")
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "vs-popup",
        {
          attrs: {
            title: "",
            active: _vm.regrasAprovacaoAutotaticasModal,
            "button-close-hidden": _vm.regraPodeFecharModal,
            id: "loading-step-endosso",
          },
          on: {
            "update:active": function ($event) {
              _vm.regrasAprovacaoAutotaticasModal = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "px-6 pb-6" },
            [
              _c("h3", { staticClass: "text-primary" }, [
                _vm._v("Processando a solicitação de endosso"),
              ]),
              _c("h6", { staticClass: "mb-10" }, [
                _vm._v("Aguarde, estamos processando sua solicitação."),
              ]),
              _vm._l(_vm.getSteps, function (step, index) {
                return _c(
                  "div",
                  { key: index },
                  [
                    _c("p", { class: ["flex", "font-semibold"] }, [
                      _c("span", {
                        staticClass:
                          "mr-2 loading-step-block vs-con-loading__container",
                        class: [`loading-step-${step.status}`],
                        attrs: { id: [`loading-step-${step.name}`] },
                      }),
                      step.status == "error"
                        ? _c(
                            "span",
                            { staticClass: "mr-2 material-icons text-danger" },
                            [_vm._v("\n            cancel\n          ")]
                          )
                        : _vm._e(),
                      step.status == "success"
                        ? _c(
                            "span",
                            { staticClass: "mr-2 material-icons text-success" },
                            [_vm._v("\n            check_circle\n          ")]
                          )
                        : _vm._e(),
                      step.status == "warning"
                        ? _c(
                            "span",
                            { staticClass: "mr-2 material-icons text-warning" },
                            [_vm._v("\n            warning\n          ")]
                          )
                        : _vm._e(),
                      step.status != "wait"
                        ? _c("span", { staticClass: "pt-2 pl-5" }, [
                            _vm._v(_vm._s(step.message)),
                          ])
                        : _vm._e(),
                    ]),
                    _c(
                      "vs-alert",
                      {
                        staticClass: "mt-4",
                        attrs: {
                          color: "warning",
                          title: "",
                          active: step.status == "warning",
                        },
                      },
                      _vm._l(step.alerts, function (message, index) {
                        return _c(
                          "p",
                          {
                            key: index,
                            staticClass: "text-warning flex font-semibold mt-2",
                          },
                          [
                            _c("span", { staticClass: "mr-2 material-icons" }, [
                              _vm._v(" warning "),
                            ]),
                            _c("span", { staticClass: "mt-1" }, [
                              _vm._v(_vm._s(message)),
                            ]),
                          ]
                        )
                      }),
                      0
                    ),
                    _c(
                      "vs-alert",
                      {
                        staticClass: "mt-4",
                        attrs: {
                          color: "danger",
                          title: "",
                          active: step.status == "error",
                        },
                      },
                      _vm._l(step.error, function (message, index) {
                        return _c(
                          "p",
                          {
                            key: index,
                            staticClass: "text-danger flex font-semibold mt-2",
                          },
                          [
                            _c("span", { staticClass: "mr-2 material-icons" }, [
                              _vm._v(" cancel "),
                            ]),
                            _c("span", { staticClass: "mt-1" }, [
                              _vm._v(_vm._s(message)),
                            ]),
                          ]
                        )
                      }),
                      0
                    ),
                  ],
                  1
                )
              }),
              _vm.apolice.PolicyUniqueId != undefined && _vm.finishRules
                ? _c(
                    "div",
                    { staticClass: "text-right" },
                    [
                      _c(
                        "vs-button",
                        {
                          staticClass: "mt-8 mr-3",
                          attrs: { color: "grey" },
                          on: {
                            click: function ($event) {
                              return _vm.goToRoute()
                            },
                          },
                        },
                        [_vm._v("Cancelar")]
                      ),
                      _vm.showButtonProsseguir
                        ? _c(
                            "vs-button",
                            {
                              staticClass: "mt-8 mr-3",
                              attrs: {
                                disabled:
                                  !_vm.habilitaButtonProsseguir &&
                                  !_vm.finishRules,
                                color: "primary",
                                type: "filled",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.goToEmissao()
                                },
                              },
                            },
                            [_vm._v("Prosseguir")]
                          )
                        : _c(
                            "vs-button",
                            {
                              staticClass: "mt-8 mr-3",
                              attrs: {
                                disabled:
                                  !_vm.habilitaButtonProsseguir &&
                                  !_vm.finishRules,
                                color: "primary",
                                type: "filled",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.goToRoute("time-line")
                                },
                              },
                            },
                            [_vm._v("Seguir para timeline")]
                          ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            2
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }