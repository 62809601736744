var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "view-upload" } }, [
    _c(
      "div",
      { ref: "view", staticClass: "view-upload", on: { click: _vm.closeView } },
      [_c("img", { attrs: { src: _vm.src, alt: "image" } })]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }